import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthorizationBoundary from 'components/AuthorizationBoundary';
import LanguageTabs from 'components/LanguageTabs';
import LayoutHeader from 'components/LayoutHeader';
import LoadingView from 'components/LoadingView';
import FaqForm from './FaqForm';
import { useCreateFaq, FAQS_QUERY } from 'hooks/use-faq';
import useAuth from 'hooks/use-auth';
import Card from 'components/Card';
import useConf from 'hooks/use-conf';
import { useToast } from 'components/Toast';
import isSuccessResponse from 'lib/isSuccessResponse';

const FAQCreate = () => {
	const { addSuccessMessage, addErrorMessage } = useToast();
	const navigate = useNavigate();
	const { config } = useConf();
	const { locale, setLocale, logOut } = useAuth();

	useEffect(() => {
		// To create new product has to be default locale (Could not be called directly, React error)
		setLocale(config.default_locale);
	}, [config.default_locale]);

	const [createFaq, { loading }] = useCreateFaq({
		onCompleted: ({ CreateFAQ }) => {
			if (isSuccessResponse(CreateFAQ, ['FAQ'], logOut, addErrorMessage, 'FAQ')) {
				addSuccessMessage('FAQ', 'Creada correctamente');
				navigate('/dashboard/faq');
			}
		},
		refetchQueries: [{ query: FAQS_QUERY, variables: { locale } }],
	});

	return (
		<AuthorizationBoundary for={['admin']}>
			<LoadingView loading={loading}>
				<LanguageTabs disabled={true} />
				<Card>
					<LayoutHeader>Crear FAQ</LayoutHeader>
					<FaqForm newFaq onSubmit={({ FaqForm }) => createFaq({ variables: { input: FaqForm, locale } })} />
				</Card>
			</LoadingView>
		</AuthorizationBoundary>
	);
};

export default FAQCreate;
