import Select from 'components/Select';
import { useToast } from 'components/Toast';
import useAuth from 'hooks/use-auth';
import { useUpdateCustomization } from 'hooks/use-costumizations';
import { plainCategoriesChildren, useProductCategories } from 'hooks/use-product-categories';
import isSuccessResponse from 'lib/isSuccessResponse';

const CustomizationCategoryInput = ({ categoryData, locale }) => {
	const { logOut } = useAuth();
	const { addSuccessMessage, addErrorMessage } = useToast();
	const { data, loading } = useProductCategories({
		variables: { locale },
	});
	const [updateCustomization] = useUpdateCustomization();

	const onSelectCategory = e => {
		updateCustomization({
			variables: {
				customizationId: categoryData.id,
				input: {
					ValueCustomizationCategoryId: Number.parseInt(e.target.value),
				},
			},
		}).then(({ data: { CustomizationUpdate } }) => {
			if (isSuccessResponse(CustomizationUpdate, ['Customization'], logOut, addErrorMessage, 'Configuración')) {
				addSuccessMessage('Configuración', 'actualizado correctamente');
			}
		});
	};

	if (loading || !categoryData) return null;

	return (
		<Select
			placeholder='Seleccionar categoria'
			value={categoryData.ValueCustomizationCategory?.id}
			options={plainCategoriesChildren(data?.ProductCategories?.List)}
			width='w-1/3'
			onChange={onSelectCategory}
			label={categoryData.description}
		/>
	);
};

export default CustomizationCategoryInput;
