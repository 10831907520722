import { useQuery, useMutation, gql } from '@apollo/client';

export const FAQINFO_FRAGMENT = /* GraphQL */ `
	fragment faqInfo on FAQ {
		id
		question
		answer
		position
	}
`;

export const FAQS_QUERY = gql`
	query FAQS($locale: String) {
		FAQS(locale: $locale) {
			...faqInfo
		}
	}
	${FAQINFO_FRAGMENT}
`;

export const FAQ_QUERY = gql`
	query FAQ($id: ID!, $locale: String) {
		FAQ(id: $id, locale: $locale) {
			...faqInfo
		}
	}
	${FAQINFO_FRAGMENT}
`;

export const FAQCREATE_MUTATION = gql`
	mutation CreateFAQ($input: FAQInput!, $locale: String) {
		CreateFAQ(input: $input, locale: $locale) {
			... on FAQ {
				...faqInfo
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
			}
		}
	}
	${FAQINFO_FRAGMENT}
`;

export const FAQUPDATE_MUTATION = gql`
	mutation UpdateFAQ($id: ID!, $input: FAQInput!, $locale: String) {
		UpdateFAQ(id: $id, input: $input, locale: $locale) {
			... on FAQ {
				...faqInfo
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
			}
		}
	}
	${FAQINFO_FRAGMENT}
`;

export const FAQDELETE_MUTATION = gql`
	mutation DeleteFAQ($id: ID!) {
		DeleteFAQ(id: $id) {
			... on Success {
				success
			}
			... on DatabaseFault {
				message
			}
			... on AuthorizationFault {
				user
				message
			}
		}
	}
`;

export const MOVE_FAQ_MUTATION = gql`
	mutation MoveFAQ($id: ID!, $direction: MoveDirection!) {
		MoveFAQ(id: $id, direction: $direction) {
			... on FAQ {
				...faqInfo
			}
			... on DatabaseFault {
				message
				databaseIssue
				obj_id
			}
			... on AuthorizationFault {
				message
			}
		}
	}
	${FAQINFO_FRAGMENT}
`;

export const useFaqs = locale => {
	const { data, loading, ...rest } = useQuery(FAQS_QUERY, {
		variables: { locale },
	});
	return { faq: data?.FAQS, loading, ...rest };
};

export const useFaq = (id, locale) =>
	useQuery(FAQ_QUERY, { variables: { id, locale }, fetchPolicy: 'cache-and-network' });

export const useCreateFaq = props => useMutation(FAQCREATE_MUTATION, props);
export const useUpdateFaq = props => useMutation(FAQUPDATE_MUTATION, props);
export const useDeleteFaq = (props, locale) =>
	useMutation(FAQDELETE_MUTATION, { ...props, refetchQueries: [{ query: FAQS_QUERY, variables: { locale } }] });
export const useMoveFaq = props => useMutation(MOVE_FAQ_MUTATION, props);

export default useFaqs;
