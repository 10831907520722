import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import LoadingView from 'components/LoadingView';
import ContentTable from 'components/ContentTable';
import LayoutHeader from 'components/LayoutHeader';
import Modal from 'components/Modal';
import isSuccessResponse from 'lib/isSuccessResponse';
import { useFaqs, useDeleteFaq, useMoveFaq, FAQS_QUERY } from 'hooks/use-faq';
import AuthorizationBoundary from 'components/AuthorizationBoundary';
import Card from 'components/Card';
import { useToast } from 'components/Toast';
import useAuth from 'hooks/use-auth';

const CreateFAQButton = () => (
	<Button as={Link} to='/dashboard/faq/new'>
		Nueva FAQ
	</Button>
);

const MoveUpButton = ({ faq, locale }) => {
	const { logOut } = useAuth();
	const { addSuccessMessage, addErrorMessage } = useToast();
	const [moveFaq, { loading }] = useMoveFaq({
		variables: { id: faq.id, direction: 'UP' },
		refetchQueries: [{ query: FAQS_QUERY, variables: { locale } }],
		onCompleted: ({ MoveFAQ }) => {
			if (isSuccessResponse(MoveFAQ, ['FAQ'], logOut, addErrorMessage, 'Moviendo FAQ')) {
				addSuccessMessage('FAQ', 'Movida hacia arriba correctamente');
			}
		},
	});

	const handleMoveUp = () => {
		if (loading) return;
		moveFaq();
	};

	return (
		<img
			onClick={handleMoveUp}
			className='cursor-pointer w-10 h-10'
			title='Subir FAQ'
			alt='Flecha hacia arriba'
			src='/images/square-caret-up-solid.svg'
		/>
	);
};

const MoveDownButton = ({ faq, locale }) => {
	const { logOut } = useAuth();
	const { addSuccessMessage, addErrorMessage } = useToast();
	const [moveFaq, { loading }] = useMoveFaq({
		variables: { id: faq.id, direction: 'DOWN' },
		refetchQueries: [{ query: FAQS_QUERY, variables: { locale } }],
		onCompleted: ({ MoveFAQ }) => {
			if (isSuccessResponse(MoveFAQ, ['FAQ'], logOut, addErrorMessage, 'Moviendo FAQ')) {
				addSuccessMessage('FAQ', 'Movida hacia abajo correctamente');
			}
		},
	});

	const handleMoveDown = () => {
		if (loading) return;
		moveFaq();
	};

	return (
		<img
			onClick={handleMoveDown}
			className='cursor-pointer w-10 h-10'
			title='Bajar FAQ'
			alt='Flecha hacia abajo'
			src='/images/square-caret-down-solid.svg'
		/>
	);
};

const FAQ = () => {
	const { locale } = useAuth();
	const { faq, loading } = useFaqs(locale);

	if (loading) return null;

	return (
		<Card>
			<LayoutHeader actions={[CreateFAQButton]}>FAQ</LayoutHeader>
			<LoadingView loading={loading} whileLoading='Buscando preguntas frecuentes'>
				<ContentTable
					content={faq}
					empty='No hay FAQ disponibles'
					columns={['Pregunta', 'Respuesta', <span className='text-right font-bold pr-6'>Acciones</span>]}
					render={(f, i) => (
						<tr key={f.id} className='text-gray-700 border-t border-dashed border-coral-300 align-top'>
							<td className='px-4 py-4 w-2/5'>{f.question}</td>
							<td className='px-4 py-4 w-2/5'>{f.answer}</td>
							<td className='px-4 py-4 flex justify-end items-center space-x-2'>
								<AuthorizationBoundary for={['admin']}>
									<Button to={`/dashboard/faq/edit/${f.id}`} as={Link}>
										<svg viewBox='0 0 20 20' fill='currentColor' className='pencil-alt w-4 h-4'>
											<path d='M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z'></path>
											<path
												fillRule='evenodd'
												d='M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z'
												clipRule='evenodd'
											></path>
										</svg>
									</Button>
									<DeleteButton id={f.id} />
									<div className='flex flex-col items-center space-y-1'>
										{i > 0 ? <MoveUpButton faq={f} locale={locale} /> : null}
										{i < faq.length - 1 ? <MoveDownButton faq={f} locale={locale} /> : null}
									</div>
								</AuthorizationBoundary>
							</td>
						</tr>
					)}
				/>
			</LoadingView>
		</Card>
	);
};

const DeleteButton = ({ id }) => {
	const { locale, logOut } = useAuth();
	const { addSuccessMessage, addErrorMessage } = useToast();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [deleteFaq, { loading }] = useDeleteFaq(
		{
			variables: { id },
			onCompleted: ({ DeleteFAQ }) => {
				if (isSuccessResponse(DeleteFAQ, ['FAQ'], logOut, addErrorMessage, 'Borrando FAQ')) {
					addSuccessMessage('FAQ', 'Borrada correctamente');
				}
			},
		},
		locale
	);

	const handleDelete = () => {
		deleteFaq();
		setIsModalOpen(false);
	};

	const modalObject = {
		title: 'Confirmar Eliminación',
		description: '¿Estás seguro de que deseas eliminar esta FAQ?',
		btnText: 'Confirmar',
		cancelBtnText: 'Cancelar',
	};

	return (
		<>
			<Button className='ml-2' onClick={() => setIsModalOpen(true)} disabled={loading}>
				<svg viewBox='0 0 20 20' fill='currentColor' className='trash w-4 h-4'>
					<path
						fillRule='evenodd'
						d='M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z'
						clipRule='evenodd'
					></path>
				</svg>
			</Button>

			<Modal
				openModal={isModalOpen}
				setOpenModal={setIsModalOpen}
				modalObject={modalObject}
				handleSubmit={handleDelete}
			/>
		</>
	);
};

export default FAQ;
