import Button from 'components/Button';
import DropdownTreeForm from 'components/DropdownTreeForm';
import DropImageArea from 'components/DropImageArea';
import InputForm from 'components/InputForm';
import LayoutTitle from 'components/LayoutTitle';
import Score from 'components/Score';
import Editor from 'components/Editor';
import { categoriesSimpleTree, useProductCategories } from 'hooks/use-product-categories';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useAuth from 'hooks/use-auth';
import useConf from 'hooks/use-conf';
import slugify from 'lib/slugify';
import CheckForm from 'components/CheckForm';
import TranslatorButton from 'components/TranslatorButton';
import translateProductCategoryForm from 'lib/translateProductCategoryForm';
import saveAllImages from 'lib/saveAllImages';
import { useSetImageAttributes } from 'hooks/use-media';
import EmptyModal from 'components/EmptyModal';
import translateLocale from 'lib/translateLocale';
import { useToast } from 'components/Toast';
import translatePlainField from 'lib/translatePlainField';
import semiParseJSON from 'lib/semiParseJSON';
import semiStringifyJSON from 'lib/semiStringifyJSON';
import handleTranslatedImage from 'lib/handleTranslatedImage';
import translateEditorField from 'lib/translateEditorField';

const emptyContent = {
	title: '',
	description: '[{"type":"paragraph","children":[{"text":""}]}]',
	subtitle1: '',
	description1: '[{"type":"paragraph","children":[{"text":""}]}]',
	subtitle2: '',
	description2: '[{"type":"paragraph","children":[{"text":""}]}]',
	subtitle3: '',
	description3: '[{"type":"paragraph","children":[{"text":""}]}]',
	subtitle4: '',
	description4: '[{"type":"paragraph","children":[{"text":""}]}]',
	subtitle5: '',
	description5: '[{"type":"paragraph","children":[{"text":""}]}]',
};
const contentOfTypeEditor = new Set([
	'description',
	'description1',
	'description2',
	'description3',
	'description4',
	'description5',
]);

const CategoryForm = ({
	newCategory = false,
	productCategory,
	isSubmitting = false,
	onSubmit,
	setDirty = () => {},
	getLazyProductCategory = () => {},
}) => {
	// Hooks and imports
	const { locale, user } = useAuth();
	const { config } = useConf();
	const { data } = useProductCategories({
		variables: { locale: config.default_locale },
	});
	const { addSuccessMessage, addErrorMessage, addInfoMessage } = useToast();
	const [setImageAttributes] = useSetImageAttributes();

	// States
	const disableNonTranslatableFields = locale !== config.default_locale || user.type === 'translator';
	const [score, setScore] = useState(0);
	const [isSuccessTranslated, setIsSuccessTranslated] = useState(false);
	const [translatorModal, setTranslatorModal] = useState(false);
	const [fromLocale, setFromLocale] = useState('');
	const [Image, setImage] = useState();
	const [imagesUploading, setImagesUploading] = useState(false);
	// Counter to increase when loading new data from the API (used to flag the editors
	// to refresh the content)
	const [refreshCounterNumber, setRefreshCounterNumber] = useState(0);

	const {
		handleSubmit,
		getValues,
		watch,
		control,
		register,
		setValue,
		reset,
		formState: { dirtyFields },
	} = useForm({
		defaultValues: {
			categoryForm: {
				name: '',
				slug: '',
				content: emptyContent,
				requiresDataSheet: false,
				requiresHealthRegister: false,
				ImageId: null,
				ParentId: null,
			},
		},
	});

	useEffect(() => {
		if (productCategory) {
			// Extract JSON and stringify the Editor values (as the Editor expects values stingified)
			const categoryContent = semiParseJSON(productCategory.content, contentOfTypeEditor) || emptyContent;
			reset({
				categoryForm: {
					name: productCategory.name || '',
					slug: productCategory.slug || '',
					content: categoryContent,
					requiresDataSheet: productCategory.parentRequiresDataSheet || productCategory.requiresDataSheet,
					requiresHealthRegister: productCategory.requiresHealthRegister || false,
					ImageId: productCategory.Image?.id || null,
					ParentId: productCategory.ParentId || null,
				},
			});
			// Creates a copy of each image element so it can be modified inside the DropfileImage
			setImage({ ...productCategory.Image });
			setDirty(false);
			setRefreshCounterNumber(v => v + 1); // Just increase the refreshCounterNumber
		}
	}, [productCategory, setImage, reset, setDirty]);

	watch(({ categoryForm }, { name }) => {
		calculateFormScore(categoryForm, setScore);
		if (name === 'categoryForm.name') {
			setValue('categoryForm.slug', slugify(categoryForm.name));
		}

		// fetch category data to set requiresDataSheet
		if (name === 'categoryForm.ParentId' && categoryForm.ParentId) {
			getLazyProductCategory({
				variables: {
					id: categoryForm.ParentId,
				},
				onCompleted: ({ ProductCategory }) => {
					setValue('categoryForm.requiresDataSheet', ProductCategory.requiresDataSheet);
				},
			});
		}
	});

	// Set the dirty state used in LanguageTabs
	useEffect(() => {
		if (Object.keys(dirtyFields).length > 0) {
			setDirty(true);
		} else {
			setDirty(false);
		}
	}, [Object.keys(dirtyFields).length]);

	useEffect(async () => {
		if (isSuccessTranslated) {
			await saveAllImages([Image], setImageAttributes, locale);
		}
	}, [isSuccessTranslated]);

	const handleOnchangeParentId = pId => {
		setValue('categoryForm.ParentId', pId);
	};

	const handleOnChangeImage = useCallback(
		newImage => {
			setImage(newImage[0]);
			setValue('categoryForm.ImageId', newImage[0].id);
		},
		[setValue]
	);

	const handleTranslateField = async (fieldName, translateMethod, aditionalProp = () => {}) => {
		setTranslatorModal(true);
		getLazyProductCategory({
			variables: { id: productCategory.id, forceLocale: true, locale: fromLocale },
		}).then(({ data }) => {
			if (data?.ProductCategory) {
				if (!data.ProductCategory[fieldName]) {
					addInfoMessage(
						'Traducción',
						`Campo seleccionado sin texto en el idioma ${translateLocale(fromLocale)}`
					);
					setTranslatorModal(false);
					return;
				}
				translateMethod(
					data.ProductCategory[fieldName],
					fromLocale,
					setTranslatorModal,
					setValue,
					`categoryForm.${fieldName}`,
					addErrorMessage,
					locale,
					config,
					aditionalProp
				);
			}
		});
	};

	const handleTranslateContentField = async (
		fieldName,
		label,
		translateMethod,
		useStringify = false,
		aditionalProp = () => {}
	) => {
		setTranslatorModal(true);
		getLazyProductCategory({
			variables: { id: productCategory.id, forceLocale: true, locale: fromLocale },
		}).then(({ data }) => {
			if (data?.ProductCategory) {
				if (!data.ProductCategory.content) {
					addInfoMessage(
						'Traducción',
						`Campo seleccionado sin texto en el idioma ${translateLocale(fromLocale)}`
					);
					setTranslatorModal(false);
					return;
				}
				try {
					const rawCommercialContent = JSON.parse(data.ProductCategory.content);
					const text = useStringify
						? JSON.stringify(rawCommercialContent[fieldName])
						: rawCommercialContent[fieldName];
					if ((useStringify && text === '[{"type":"paragraph","children":[{"text":""}]}]') || !text) {
						addInfoMessage(
							'Traducción',
							`Campo seleccionado sin texto en el idioma ${translateLocale(fromLocale)}`
						);
						setTranslatorModal(false);
						return;
					}
					translateMethod(
						text,
						fromLocale,
						setTranslatorModal,
						setValue,
						`categoryForm.content.${fieldName}`,
						addErrorMessage,
						locale,
						config,
						aditionalProp
					);
				} catch {
					setTranslatorModal(false);
					addErrorMessage(`Error al transformar "${label}"`);
				}
			}
		});
	};

	return (
		<div>
			<form
				onSubmit={handleSubmit(({ categoryForm }) => {
					onSubmit({
						...categoryForm,
						content: semiStringifyJSON(categoryForm.content, contentOfTypeEditor),
						slug: slugify(categoryForm.slug),
					});
				})}
				disabled={isSubmitting}
			>
				<Score data={score} name='de categoría' content='' />
				<div className='flex items-center w-full max-w-screen-md mb-4'>
					<span
						className={`${
							disableNonTranslatableFields ? 'text-gray-500' : 'text-gray-700'
						} font-semibold text-lg w-1/3`}
					>
						Categoría Superior
					</span>
					<DropdownTreeForm
						name='categoryForm.ParentId'
						control={control}
						initialValue={getValues('categoryForm.ParentId')}
						disabled={disableNonTranslatableFields}
						noneSelectedLabel='Sin categoría padre'
						treeData={categoriesSimpleTree(null, data?.ProductCategories?.List)}
						onChange={handleOnchangeParentId}
						selectCategoryParent={true}
						productCategory={productCategory}
					/>
				</div>

				<LayoutTitle>Descripción categoría. Texto visible en la web</LayoutTitle>
				{!newCategory && (
					<div className='flex mb-6'>
						<p className='text-center items-center mb-1 text-lg font-medium'>Traducir todo</p>
						<TranslatorButton
							onClick={async () => {
								setIsSuccessTranslated(false);
								const isSuccess = await translateProductCategoryForm(
									{
										id: productCategory.id,
										...getValues('categoryForm'),
										Image,
									},
									fromLocale,
									locale,
									config,
									setTranslatorModal,
									setValue,
									setRefreshCounterNumber,
									getLazyProductCategory,
									setImage,
									addSuccessMessage,
									addErrorMessage
								);
								if (isSuccess) {
									await handleSubmit(data => {
										onSubmit({
											...data.categoryForm,
											content: semiStringifyJSON(data.categoryForm.content, contentOfTypeEditor),
											slug: slugify(data.categoryForm.slug),
										});
									})();
									setIsSuccessTranslated(isSuccess);
								}
							}}
							fromLocale={fromLocale}
							setFromLocale={setFromLocale}
							tooltip={
								<ul className='list-disc pl-4'>
									{!fromLocale ? (
										<li>Elegir el idioma original del que traducir.</li>
									) : (
										<li>Traducir de {translateLocale(fromLocale)}.</li>
									)}
									<li>No traducirá campos que ya tengan texto en esta ficha.</li>
								</ul>
							}
						/>
					</div>
				)}

				<InputForm
					name='categoryForm.name'
					control={control}
					register={register}
					label='Nombre de la Categoría'
					containerclassname='my-4'
					className='border border-coral-300 py-2 px-4 text-lg'
					placeholder='Añadir nombre'
					required
				>
					{!newCategory ? (
						<TranslatorButton
							onClick={async () => handleTranslateField('name', translatePlainField)}
							fromLocale={fromLocale}
							setFromLocale={setFromLocale}
						/>
					) : null}
				</InputForm>

				<InputForm
					name='categoryForm.slug'
					control={control}
					register={register}
					containerclassname='my-4'
					className='border border-coral-300 py-2 px-4 text-lg'
					label='Slug'
					placeholder='Añadir slug'
					required
				/>

				<InputForm
					name='categoryForm.content.title'
					control={control}
					register={register}
					label='Título de la Categoría'
					containerclassname='my-4'
					className='border border-coral-300 py-2 px-4 text-lg'
					placeholder='Añadir título'
					description='Título H1 de la página de la categoría'
					required
				>
					<TranslatorButton
						onClick={async () => handleTranslateContentField('title', 'Título', translatePlainField)}
						fromLocale={fromLocale}
						setFromLocale={setFromLocale}
					/>
				</InputForm>

				<Editor
					name='categoryForm.content.description'
					control={control}
					containerclassname='my-4'
					className='border mt-1 p-4'
					label='Descripción breve'
					refreshContentNumber={refreshCounterNumber}
				>
					<TranslatorButton
						onClick={async () =>
							handleTranslateContentField(
								'description',
								'Descripción breve',
								translateEditorField,
								true,
								setRefreshCounterNumber
							)
						}
						fromLocale={fromLocale}
						setFromLocale={setFromLocale}
					/>
				</Editor>

				<DropImageArea
					path='category'
					name='categoryForm.Image'
					label='Imagen'
					whileIdleText='Pincha o Suelta tus Imágenes aquí'
					Images={[Image]}
					onChange={handleOnChangeImage}
					onDelete={() => setImage(null)}
					onTranslateImage={translatedImage => handleTranslatedImage(setImage, translatedImage)}
					setUploading={setImagesUploading}
					specifications={
						<ul className='list-disc pl-4'>
							<li>Tamaño máximo 500kb</li>
						</ul>
					}
					setTranslatorModal={setTranslatorModal}
					fromLocale={fromLocale}
					setFromLocale={setFromLocale}
				/>
				<InputForm
					name='categoryForm.content.subtitle1'
					control={control}
					register={register}
					label='Subtítulo 1'
					containerclassname='my-4'
					className='border border-coral-300 py-2 px-4 text-lg'
					placeholder='Añadir subtitulo'
				>
					<TranslatorButton
						onClick={async () =>
							handleTranslateContentField('subtitle1', 'Subtítulo 1', translatePlainField)
						}
						fromLocale={fromLocale}
						setFromLocale={setFromLocale}
					/>
				</InputForm>

				<Editor
					name='categoryForm.content.description1'
					control={control}
					containerclassname='my-4'
					className='border mt-1 p-4'
					label='Descripción 1'
					refreshContentNumber={refreshCounterNumber}
				>
					<TranslatorButton
						onClick={async () =>
							handleTranslateContentField(
								'description1',
								'Descripción 1',
								translateEditorField,
								true,
								setRefreshCounterNumber
							)
						}
						fromLocale={fromLocale}
						setFromLocale={setFromLocale}
					/>
				</Editor>

				<InputForm
					name='categoryForm.content.subtitle2'
					control={control}
					register={register}
					label='Subtítulo 2'
					containerclassname='my-4'
					className='border border-coral-300 py-2 px-4 text-lg'
					placeholder='Añadir subtitulo'
				>
					<TranslatorButton
						onClick={async () =>
							handleTranslateContentField('subtitle2', 'Subtítulo 2', translatePlainField)
						}
						fromLocale={fromLocale}
						setFromLocale={setFromLocale}
					/>
				</InputForm>

				<Editor
					name='categoryForm.content.description2'
					control={control}
					containerclassname='my-4'
					className='border mt-1 p-4'
					label='Descripción 2'
					refreshContentNumber={refreshCounterNumber}
				>
					<TranslatorButton
						onClick={async () =>
							handleTranslateContentField(
								'description2',
								'Descripción 2',
								translateEditorField,
								true,
								setRefreshCounterNumber
							)
						}
						fromLocale={fromLocale}
						setFromLocale={setFromLocale}
					/>
				</Editor>

				<InputForm
					name='categoryForm.content.subtitle3'
					control={control}
					register={register}
					label='Subtítulo 3'
					containerclassname='my-4'
					className='border border-coral-300 py-2 px-4 text-lg'
					placeholder='Añadir subtitulo'
				>
					<TranslatorButton
						onClick={async () =>
							handleTranslateContentField('subtitle3', 'Subtítulo 3', translatePlainField)
						}
						fromLocale={fromLocale}
						setFromLocale={setFromLocale}
					/>
				</InputForm>

				<Editor
					name='categoryForm.content.description3'
					control={control}
					containerclassname='my-4'
					className='border mt-1 p-4'
					label='Descripción 3'
					refreshContentNumber={refreshCounterNumber}
				>
					<TranslatorButton
						onClick={async () =>
							handleTranslateContentField(
								'description3',
								'Descripción 3',
								translateEditorField,
								true,
								setRefreshCounterNumber
							)
						}
						fromLocale={fromLocale}
						setFromLocale={setFromLocale}
					/>
				</Editor>

				<InputForm
					name='categoryForm.content.subtitle4'
					control={control}
					register={register}
					label='Subtítulo 4'
					containerclassname='my-4'
					className='border border-coral-300 py-2 px-4 text-lg'
					placeholder='Añadir subtitulo'
				>
					<TranslatorButton
						onClick={async () =>
							handleTranslateContentField('subtitle4', 'Subtítulo 4', translatePlainField)
						}
						fromLocale={fromLocale}
						setFromLocale={setFromLocale}
					/>
				</InputForm>

				<Editor
					name='categoryForm.content.description4'
					control={control}
					containerclassname='my-4'
					className='border mt-1 p-4'
					label='Descripción 4'
					refreshContentNumber={refreshCounterNumber}
				>
					<TranslatorButton
						onClick={async () =>
							handleTranslateContentField(
								'description4',
								'Descripción 4',
								translateEditorField,
								true,
								setRefreshCounterNumber
							)
						}
						fromLocale={fromLocale}
						setFromLocale={setFromLocale}
					/>
				</Editor>

				<InputForm
					name='categoryForm.content.subtitle5'
					control={control}
					register={register}
					label='Subtítulo 5'
					containerclassname='my-4'
					className='border border-coral-300 py-2 px-4 text-lg'
					placeholder='Añadir subtitulo'
				>
					<TranslatorButton
						onClick={async () =>
							handleTranslateContentField('subtitle5', 'Subtítulo 5', translatePlainField)
						}
						fromLocale={fromLocale}
						setFromLocale={setFromLocale}
					/>
				</InputForm>

				<Editor
					name='categoryForm.content.description5'
					control={control}
					containerclassname='my-4'
					className='border mt-1 p-4'
					label='Descripción 5'
					refreshContentNumber={refreshCounterNumber}
				>
					<TranslatorButton
						onClick={async () =>
							handleTranslateContentField(
								'description5',
								'Descripción 5',
								translateEditorField,
								true,
								setRefreshCounterNumber
							)
						}
						fromLocale={fromLocale}
						setFromLocale={setFromLocale}
					/>
				</Editor>

				<CheckForm
					name='categoryForm.requiresDataSheet'
					label='Ficha técnica requerida (valor de categoría padre por defecto)'
					control={control}
					disabled={productCategory?.parentRequiresDataSheet}
				/>
				<CheckForm
					name='categoryForm.requiresHealthRegister'
					label='Requiere Registro Sanitario'
					control={control}
				/>
				<Button className='mr-auto mt-4 w-32' disabled={isSubmitting || imagesUploading} type='submit'>
					{isSubmitting ? 'Guardando' : 'Guardar'}
				</Button>
			</form>
			<EmptyModal openModal={translatorModal}>
				<div className='relative flex w-[270px] h-[270px] rounded-full items-center justify-center z-40 overflow-hidden'>
					<div className='absolute flex w-[270px] aspect-square animate-spin-slow bg-gradient-to-r from-black to-coral-500' />
					<div className='flex items-center justify-center bg-coral-500 z-50 w-[220px] h-[220px] rounded-full'>
						<p className='text-5xl font-medium'>Traduciendo</p>
					</div>
				</div>
			</EmptyModal>
		</div>
	);
};

// Using calculateFormScore in combination with watch "score" state
const calculateFormScore = (formData, setScore) => {
	if (formData) {
		const formDataFiltered = [
			...Object.entries(formData).filter(e => !['ParentId', 'content', 'requiresDataSheet'].includes(e[0])),
			...Object.entries(formData.content),
		];
		let total = formDataFiltered.length;
		let count = 0;

		for (var [, value] of formDataFiltered) {
			if (value && value !== '[{"type":"paragraph","children":[{"text":""}]}]') {
				count++;
			}
		}
		setScore((100 * count) / total);
	} else {
		// When no data 0%
		setScore(0);
	}
};

export default CategoryForm;
