import Card from 'components/Card';
import LayoutTitle from 'components/LayoutTitle';
import CustomizationImageInput from './CustomizationImageInput';
import CustomizationTextInput from './CustomizationTextInput';
import CustomizationCategoryInput from './CustomizationCategoryInput';
import React from 'react';

const CustomizationFolder = ({ folder, setDirty = () => {}, locale }) => {
	const selectInputType = customization => {
		switch (customization.valueType) {
			case 'image':
				return <CustomizationImageInput setDirty={setDirty} imageData={customization} locale={locale} />;
			case 'plainText':
			case 'translatableText':
			case 'translatableTextEditor':
				return <CustomizationTextInput setDirty={setDirty} textData={customization} locale={locale} />;
			case 'category':
				return <CustomizationCategoryInput setDirty={setDirty} categoryData={customization} locale={locale} />;
			default:
				return null;
		}
	};
	return (
		<Card className='mt-10'>
			<LayoutTitle>{folder.description}</LayoutTitle>
			<p>{folder.valuePlainText}</p>
			{folder.children.map(customization => (
				<div key={customization.id} className='mt-5'>
					{selectInputType(customization)}
				</div>
			))}
		</Card>
	);
};

export default CustomizationFolder;
