import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Card from 'components/Card';
import ContentTable from './ContentTable';
import Currency from './Currency';
import LayoutHeader from 'components/LayoutHeader';
import LoadingView from 'components/LoadingView';
import Pagination from 'components/Pagination';
import { useToast } from 'components/Toast';
import Tooltip from './Tooltip';

import printUserName from 'lib/printUserName';
import translasteStatus from 'lib/translasteStatus';
import columnTitleElement from 'lib/columnTitleElement';
import formatDate from 'lib/formatDate';

import useAuth from 'hooks/use-auth';
import { useLazyOrders } from 'hooks/use-orders';
import isSuccessResponse from 'lib/isSuccessResponse';

// Merchants don't receive the grossTotalPrice, so we must calculate it from their MerchantShipping
// which is allways one for each Delivery, as only their own is returned
const getOrderTotal = order =>
	order?.grossTotalPrice
		? order?.grossTotalPrice
		: order.Deliveries.map(delivery => delivery.MerchantShippings[0]?.grossTotalPrice).reduce(
				(sum, total) => sum + total
		  );

const OrderList = ({
	pageScope,
	title,
	statusList = [
		{ label: 'Todas', scope: 'all' },
		{ label: 'Notificadas', scope: 'notification' },
		{ label: 'Creado', scope: 'new' },
		{ label: 'Procesando', scope: 'processing' },
		{ label: 'Preparada', scope: 'prepared' },
		{ label: 'Enviada', scope: 'shipped' },
		{ label: 'Incidencia', scope: 'incident' },
		{ label: 'Anulada', scope: 'canceled' },
		{ label: 'Devuelto', scope: 'returned' },
		{ label: 'Completada', scope: 'completed' },
	],
	paginationLimit = 50,
	userId,
}) => {
	const { addErrorMessage } = useToast();
	const { user, logOut } = useAuth();
	const [scope, setScope] = useState(pageScope || 'all');
	const [orderScope, setOrderScope] = useState('created_asc');
	const [offset, setOffset] = useState(0);
	const [orders, setOrders] = useState(null);
	const [getLazyOrders, { data, loading }] = useLazyOrders({
		scope,
		order_scope: orderScope,
		limit: paginationLimit,
		offset,
		userId,
	});

	// Excecute once at page load
	useEffect(() => {
		getLazyOrders();
	}, [getLazyOrders]);

	useEffect(() => {
		if (data && isSuccessResponse(data.MerchantOrders, ['OrdersList'], logOut, addErrorMessage, 'Pedidos')) {
			setOrders(data.MerchantOrders);
		}
	}, [data, addErrorMessage]);

	const filterStatusList = () => {
		return statusList?.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setScope(scope);
				setOffset(0);
				getLazyOrders();
			},
		}));
	};

	const orderByDate = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'created_asc' },
			{ label: 'Descendente', scope: 'created_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
				getLazyOrders();
			},
		}));
	};

	const orderByCustomer = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'email_asc' },
			{ label: 'Descendente', scope: 'email_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
				getLazyOrders();
			},
		}));
	};

	const orderByTotal = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'totalPrice_asc' },
			{ label: 'Descendente', scope: 'totalPrice_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
				getLazyOrders();
			},
		}));
	};

	const CardOrDiv = userId ? 'div' : Card;

	const formatDeliveriesTooltip = deliveries => {
		if (!deliveries || deliveries.length === 0) {
			return <p>No hay envíos para este pedido.</p>;
		}

		return (
			<div className='p-2'>
				{deliveries.map(delivery => (
					<div key={delivery.number} className='mb-2'>
						<p>
							<strong>Envío {delivery.number}:</strong> {translasteStatus(delivery.status)}
						</p>
					</div>
				))}
			</div>
		);
	};

	return (
		<>
			<CardOrDiv className='mb-20'>
				<LayoutHeader>{title}</LayoutHeader>
				<Pagination
					offset={offset}
					setOffset={setOffset}
					count={orders?.count}
					hasMore={orders?.hasMore}
					limit={paginationLimit}
				/>
				<LoadingView loading={loading} whileLoading='Buscando órdenes...'>
					<ContentTable
						columns={
							user.type === 'admin'
								? [
										columnTitleElement('Nº Pedido'),
										columnTitleElement('Cliente', orderByCustomer),
										columnTitleElement('Proveedor'),
										columnTitleElement('Fecha', orderByDate),
										columnTitleElement('Estado', filterStatusList),
										columnTitleElement('Total', orderByTotal),
										columnTitleElement('Nota'),
								  ]
								: [
										columnTitleElement('Nº Pedido'),
										columnTitleElement('Cliente', orderByCustomer),
										columnTitleElement('Fecha', orderByDate),
										columnTitleElement('Estado', filterStatusList),
										columnTitleElement('Total', orderByTotal),
										columnTitleElement('Nota'),
								  ]
						}
						content={orders?.List}
						render={(order, index) =>
							order && (
								<tr key={index} className='text-gray-700 border-t border-dashed border-coral-300'>
									<td className='px-4 py-2'>
										<Link
											className='border-b border-dashed border-coral-300 hover:text-coral-500 tranisiton-color duration-100'
											to={`/dashboard/pedido/${order.id}`}
											data-testid='order'
										>
											{order.id}
										</Link>
									</td>
									<td className='px-4 py-2'>
										{user.type === 'admin' ? (
											<Link
												className='border-b border-dashed border-coral-300 hover:text-coral-500 transition-color duration-100'
												to={`/dashboard/clientes/${order.User?.id}`}
											>
												{order.email || printUserName(order.User)}
											</Link>
										) : (
											<span>{order.email || printUserName(order.User)}</span>
										)}
									</td>
									{user.type === 'admin' && (
										<td className='px-4 py-2'>
											<span className='flex flex-col'>
												{order?.Merchants.map((merchant, index) => (
													<p key={index}>{merchant.commercialName}</p>
												))}
											</span>
										</td>
									)}
									<td className='px-4 py-2'>{formatDate(order.orderDate)}</td>
									<td className={`px-4 py-2 ${order.hasIncident ? 'text-coral-500 flex gap-2' : ''}`}>
										<Tooltip
											tooltip={formatDeliveriesTooltip(order.Deliveries)}
											triangleClassName='mr-[12px] self-end'
											containerClassName='max-w-[300px] w-[max-content] right-[50%] mb-8'
										>
											<span className={`flex items-center ${order.hasIncident ? 'gap-2' : ''}`}>
												{order.hasIncident ? 'Incidencia' : translasteStatus(order.status)}
												{order.hasIncident ? (
													<img
														src='/images/warning.svg'
														alt='Icono de advertencia'
														width='25'
														height='25'
													/>
												) : null}
											</span>
										</Tooltip>
									</td>
									<td className='px-4 py-2'>
										<Currency>{getOrderTotal(order)}</Currency>
									</td>
									<td className='px-4 py-2'>
										{order.shippingNotes ? (
											<Tooltip
												tooltip={order.shippingNotes}
												triangleClassName='mr-[12px] self-end'
												containerClassName='max-w-[21vw] w-[max-content] right-[50%] mb-8'
											>
												<img
													src='/images/note.svg'
													alt='Icono de nota'
													width='25'
													height='25'
												/>
											</Tooltip>
										) : (
											''
										)}
									</td>
								</tr>
							)
						}
						empty={<p>No hay pedidos</p>}
					/>
				</LoadingView>
			</CardOrDiv>
		</>
	);
};

export default OrderList;
