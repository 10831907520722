import React, { useEffect, useState } from 'react';
import AuthorizationBoundary from 'components/AuthorizationBoundary';
import Card from 'components/Card';
import CustomizationFolder from './CustomizationFolder';
import LanguageTabs from 'components/LanguageTabs';
import LayoutHeader from 'components/LayoutHeader';
import SearchBox from 'components/SearchBox';
import Button from 'components/Button';
import { useToast } from 'components/Toast';
import { useLazyCustomizations } from 'hooks/use-costumizations';
import useConf from 'hooks/use-conf';
import useAuth from 'hooks/use-auth';
import webhook from 'lib/webhook.js';

const Customization = () => {
	// Start with a default language
	const [dirty, setDirty] = useState(false);
	const { addSuccessMessage, addErrorMessage } = useToast();
	const { locale } = useAuth();
	// Create an state for every customization folder
	const [foldersOrganized, setFoldersOrganized] = useState(false);
	const [folders, setFolders] = useState(null);
	const [customizationSearch, setCustomizationSearch] = useState('');
	const [pageSlug, setPageSlug] = useState('none');
	const { config } = useConf();

	const [getLazyCustomizations, { loading }] = useLazyCustomizations({
		variables: {
			searchByDescription: customizationSearch,
			searchByKey: '',
			locale,
		},
		onCompleted: ({ Customizations }) => {
			if (Customizations) {
				// Filter only valueType === 'folder'
				let folders = Customizations.filter(conf => conf.valueType === 'folder');
				// Create a copy of folders that can be modified
				folders = folders.map(conf => JSON.parse(JSON.stringify(conf)));
				// Add a children for each object in folders containing related configs objects
				for (const folder of folders) {
					folder.children = Customizations.filter(conf => conf.key.startsWith(folder.key));
				}
				setFolders(folders);
				setFoldersOrganized(true);
			}
		},
	});

	// Excecute once at page load and when change locale
	useEffect(() => {
		setFoldersOrganized(false);
		getLazyCustomizations();
	}, [locale, getLazyCustomizations]);

	const handleSearch = input => {
		setCustomizationSearch(input);
		getLazyCustomizations();
	};

	const handleClearSearch = () => {
		setCustomizationSearch('');
		getLazyCustomizations();
	};

	return (
		<AuthorizationBoundary for={['admin']}>
			<LanguageTabs dirty={dirty} />
			<Card>
				<LayoutHeader>Personalización</LayoutHeader>
				<SearchBox
					placeholder='Busca palabra clave'
					search={handleSearch}
					loading={loading}
					clearSearch={handleClearSearch}
				/>
				<div className='flex mt-10'>
					<Button
						className='rounded-r-none'
						disabled={pageSlug === 'none'}
						onClick={() =>
							webhook(
								config,
								config.locales.map(locale => `/${locale}${pageSlug}`)
							)
								.then(() => {
									addSuccessMessage(
										'Página revalidada',
										`Página "${pageSlug}" actualizada en la tienda`
									);
								})
								.catch(error => {
									addErrorMessage('Revalidar tienda', error.message);
								})
						}
						type='button'
						tooltip='Regenerar en la tienda la página seleccionada'
					>
						Regenerar página
					</Button>
					<select
						className='border-2 bor-l-0 border-coral-300 mr-4 rounded-r-md'
						value={pageSlug}
						onChange={e => setPageSlug(e.target.value)}
					>
						<option value='none'>Seleccionar</option>
						{pagesList.map(page => (
							<option key={page.name} value={page.value}>
								{page.name}
							</option>
						))}
					</select>
				</div>
				{foldersOrganized &&
					folders?.map(
						folder =>
							folder.children?.length > 0 && (
								<CustomizationFolder
									key={folder.key}
									folder={folder}
									setDirty={setDirty}
									locale={locale}
								/>
							)
					)}
			</Card>
		</AuthorizationBoundary>
	);
};

const pagesList = [
	{ value: '', name: 'Home' },
	{ value: '/informate', name: 'Infórmate' },
	{ value: '/unirme', name: 'Unirme' },
	{ value: '/contacto', name: 'Contacto' },
	{ value: '/faq', name: 'FAQ' },
	{ value: '/asociados', name: 'Lista de asociados' },
	{ value: '/login', name: 'Login' },
	{ value: '/register', name: 'Registro' },
	{ value: '/restore-password', name: 'Restaurar contraseña' },
	{ value: '/fira-de-tardor-2024', name: 'Fira de tardor 2024' },
];

export default Customization;
